/*
-----------------------------------------------------------------------
	Service CSS
-----------------------------------------------------------------------
*/

.static-media {
  padding-top: 90px;
  @media #{$xs-device} {
    padding-top: 80px;
  }
  @media #{$sm-device} {
    padding-top: 80px;
  }
  .media {
    padding-bottom: 30px;
    border-bottom: 1px solid #e5e5e5;
    .icon {
      align-self: center;
      color: #8f1b1b;
      font-size: 48px;
    }

    .title {
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
      font-size: 20px;
      color: #000;
      margin-bottom: 10px;
      @include min-screen(992) {
        font-size: 24px;
      }
    }
    p {
      color: #666666;
      font-size: 14px;
    }
  }
  .media-body {
    flex: 1;
  }
}

/*
-----------------------------------------------------------------------
	Service CSS
-----------------------------------------------------------------------
*/
.service-area {
  padding: 125px 0px;
  @media #{$lg-device} {
    padding: 100px 0px;
  }
  @media #{$sm-device} {
    padding: 80px 0px;
  }
  .section-title {
    margin-bottom: 90px;
    @media #{$lg-device} {
      margin-bottom: 70px;
    }
    @media #{$sm-device} {
      margin-bottom: 50px;
    }
  }
}

.service-item {
  .icon {
    color: $theme-color;
    font-size: 46px;
    line-height: 1;
    margin-bottom: 35px;
  }
  .content {
    position: relative;
    .service-name {
      color: #010101;
      font-size: 18px;
      letter-spacing: 0;
      margin-bottom: 18px;
    }
    p {
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 30px;
    }
    .btn-link {
      color: $black;
      font-size: 14px;
      letter-spacing: 0.25px;
      i {
        color: $black-444;
        font-size: 17px;
        margin-left: 4px;
        position: relative;
        top: 1px;
      }
    }
  }
}

.service-style2 {
  border: 1px solid #e3e3e3;
  margin: 0;
  .service-item {
    border-bottom: 1px solid $white;
    border-right: 1px solid #ebebeb;
    padding: 50px 30px 35px 40px;
    position: relative;
    @include transition(0.3s);
    @media #{$lg-device} {
      padding: 40px 20px;
    }

    &:before {
      background-color: $theme-color;
      content: "";
      height: 0;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      @include transition(0.1s);
    }

    .icon {
      align-items: flex-end;
      display: flex;
      min-height: 48px;
      padding-left: 1px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      border-bottom-color: #ebebeb;
    }
    &:nth-child(4),
    &:nth-child(8) {
      border-right-color: $white;
    }
    &:nth-child(5),
    &:nth-child(6) {
      @media #{$md-device} {
        border-bottom: 1px solid #ebebeb;
      }
    }

    &:hover {
      background-color: #f3f3f3;
      &:before {
        height: 4px;
      }
      .content {
        .btn-link {
          color: $theme-color;
        }
      }
    }
  }
}

.service-category {
  .category-item {
    border-bottom: 1px solid rgba($white, 0.5);
    padding-bottom: 26px;
    position: relative;
    h4 {
      color: $white;
      font-size: 24px;
      margin-bottom: 1px;
      text-transform: uppercase;
      @media #{$md-device} {
      }
      @media #{$sm-device} {
        font-size: 20px;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 3px;
      }
      @media #{$xs-device} {
      }
      @media #{$xxs-device} {
      }
    }
    p {
      color: $white;
      @media #{$md-device} {
      }
      @media #{$sm-device} {
      }
      @media #{$xs-device} {
      }
    }
    .icon {
      color: $white;
      font-size: 48px;
      position: absolute;
      right: 0;
      top: -21px;
      @media #{$md-device} {
      }
      @media #{$sm-device} {
      }
      @media #{$xxs-device} {
      }
    }
  }
}

.service-slider-container {
  margin-top: 161px;
  @media #{$lg-device} {
    margin-top: 131px;
  }
  @media #{$md-device} {
    margin-top: 106px;
  }
  @media #{$sm-device} {
    margin-top: 86px;
  }
  @media #{$xs-device} {
    margin: 66px auto 0;
    max-width: 300px;
  }
  @media (max-width: 461px) {
    margin-top: 55px;
  }
  .service-slider {
    &.service-category {
      .category-item {
        padding-top: 4px;
        .icon {
          top: -17px;
        }
      }
    }
  }
}
